import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryHandler } from './base.api';
import { sysConfig } from '@utils';
import {
  GlobalWorkflowItem,
  PostWorflowFiltersResult,
  TeamConfigurationItem,
  WorkerPoolCategoryItem,
  WorkerPoolItem,
  WorkflowItem,
  WorkflowStepFilterItem,
} from '@interfaces';

export const mahonApi = createApi({
  baseQuery: baseQueryHandler,
  reducerPath: 'mahonApi',
  endpoints: (builder) => ({
    getTeamConfiguration: builder.query<TeamConfigurationItem, number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/configuration`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getTeamWorkerPools: builder.query<WorkerPoolItem[], number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/workerpool`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getTeamWorkFlows: builder.query<WorkflowItem[], number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/allworkflows`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getGlobalWorkFlows: builder.query<GlobalWorkflowItem[], void>({
      query: () => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/global`,
        method: 'GET',
      }),
    }),
    getWorkflowById: builder.query<WorkflowItem, number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/${payload}`,
        method: 'GET',
      }),
    }),
    getTeamWorflowFlag: builder.query<boolean, { teamId: number; workflowId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/candirect/${payload.workflowId}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getTeamWorkFlowStepFilters: builder.query<WorkflowStepFilterItem[], { teamId: number; stepIds: number[] }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/filters/steps?${payload.stepIds
          .map((x) => `stepId=${x}&`)
          .toString()
          .replaceAll(',', '')
          .slice(0, -1)}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getWorkerPoolCategories: builder.query<WorkerPoolCategoryItem[], number>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workerpools/category`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    putTeamConfiguration: builder.mutation<void, { data: TeamConfigurationItem; teamId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/configuration`,
        method: 'PUT',
        body: payload.data,
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    postWorkerPool: builder.mutation<void, { data: WorkerPoolItem; teamId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/workerpool`,
        method: 'POST',
        body: payload.data,
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    putWorkerPools: builder.mutation<void, { data: WorkerPoolItem[]; teamId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/team/workerpool`,
        method: 'PUT',
        body: payload.data,
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    postWorkflowFilters: builder.mutation<PostWorflowFiltersResult, { data: WorkflowStepFilterItem[]; teamId: number; workflowId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/filters/${payload.workflowId}`,
        method: 'POST',
        body: { WorkflowStepFilters: payload.data },
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    putWorkflowFlag: builder.mutation<PostWorflowFiltersResult, { flagValue: boolean; teamId: number; workflowId: number }>({
      query: (payload) => ({
        url: `${sysConfig.mahonUri}/api/v1/workflow/candirect/${payload.workflowId}`,
        method: 'PUT',
        body: JSON.stringify(payload.flagValue),
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
  }),
});

export const {
  useGetTeamConfigurationQuery,
  usePutTeamConfigurationMutation,
  useGetTeamWorkerPoolsQuery,
  usePutWorkerPoolsMutation,
  usePostWorkerPoolMutation,
  useGetWorkerPoolCategoriesQuery,
  useGetTeamWorkFlowsQuery,
  useGetTeamWorkFlowStepFiltersQuery,
  usePostWorkflowFiltersMutation,
  useGetTeamWorflowFlagQuery,
  usePutWorkflowFlagMutation,
  useGetGlobalWorkFlowsQuery,
  useGetWorkflowByIdQuery,
} = mahonApi;
