import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Spinner, RenderRoutes, AppStateProvider } from '@components';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAppSelector } from '@hooks';
import { CssBaseline } from '@mui/material';
import { LEXACOM_DARK_THEME, LEXACOM_LIGHT_THEME } from './constants/lexacomThemes';

const lexacomDarkTheme = createTheme(LEXACOM_DARK_THEME);
const lexacomLightTheme = createTheme(LEXACOM_LIGHT_THEME);

export const App = () => {
  const { isDarkMode } = useAppSelector((x) => x.app);
  return (
    <ThemeProvider theme={isDarkMode ? lexacomDarkTheme : lexacomLightTheme}>
      <CssBaseline />
      <BrowserRouter>
        <React.Suspense fallback={<Spinner />}>
          <AppStateProvider>
            <RenderRoutes />
          </AppStateProvider>
        </React.Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};
