import { mahonApi } from '@apis';
import {
  GlobalWorkflowItem,
  TeamConfigurationItem,
  TeamConfigurationLookupItem,
  WorkerPoolCategoryItem,
  WorkerPoolItem,
  WorkflowItem,
  WorkflowStepFilterItem,
} from '@interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type MahonState = {
  teamConfiguration: TeamConfigurationItem | null;
  workflow: WorkflowItem | null;
  workerPools: WorkerPoolItem[];
  workerPoolCategories: WorkerPoolCategoryItem[];
  unlockedWorkerPools: WorkerPoolItem[];
  workFlows: WorkflowItem[];
  workflowStepFilters: WorkflowStepFilterItem[];
  workflowFlag: boolean;
  globalWorkflows: GlobalWorkflowItem[];
};

const initialState: MahonState = {
  teamConfiguration: null,
  workflow: null,
  workerPools: [],
  unlockedWorkerPools: [],
  workerPoolCategories: [],
  workFlows: [],
  workflowStepFilters: [],
  workflowFlag: false,
  globalWorkflows: [],
};

export const mahonSlice = createSlice({
  name: 'mahon',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(mahonApi.endpoints.getTeamConfiguration.matchFulfilled, (state, { payload }) => {
      state.teamConfiguration = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getGlobalWorkFlows.matchFulfilled, (state, { payload }) => {
      state.globalWorkflows = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getWorkflowById.matchFulfilled, (state, { payload }) => {
      state.workflow = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getTeamWorflowFlag.matchFulfilled, (state, { payload }) => {
      state.workflowFlag = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getWorkerPoolCategories.matchFulfilled, (state, { payload }) => {
      state.workerPoolCategories = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getTeamWorkFlows.matchFulfilled, (state, { payload }) => {
      state.workFlows = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getTeamWorkFlowStepFilters.matchFulfilled, (state, { payload }) => {
      state.workflowStepFilters = payload;
    });
    builder.addMatcher(mahonApi.endpoints.getTeamWorkerPools.matchFulfilled, (state, { payload }) => {
      state.workerPools = payload.sort((a, b) => a.id - b.id);
      state.unlockedWorkerPools = payload.filter((item) => !item.locked);
    });
  },
  reducers: {
    updateTeamConfigurationLookups: (state, action: PayloadAction<TeamConfigurationLookupItem>) => {
      if (state.teamConfiguration) {
        const index = state.teamConfiguration.lookups.findIndex((x) => x.mediaConfigurationId === action.payload.mediaConfigurationId);
        if (index !== -1) {
          state.teamConfiguration.lookups[index] = action.payload;
        }
      }
    },
  },
});

export const { updateTeamConfigurationLookups } = mahonSlice.actions;
