import { useGetGlobalWorkFlowsQuery, useGetTeamNamesQuery } from '@apis';
import { TableIcons, Icons, TeamNameTable } from '@components';
import { RoutePath } from '@constants';
import { useAppSelector } from '@hooks';
import MaterialTable from '@material-table/core';
import { Grid, Paper } from '@mui/material';
import { setHelmet, setBreadcrumbs } from '@slices';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

const WorkflowTemplates = (): JSX.Element => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { globalWorkflows } = useAppSelector((x) => x.mahon);
  const { teamNames } = useAppSelector((x) => x.team);
  const [teamIds, setTeamIds] = useState<string>('');
  useGetTeamNamesQuery(teamIds, { refetchOnMountOrArgChange: true, skip: !teamIds });
  const {
    isSuccess,
    isLoading: isGettingWorkerFlows,
    isError,
  } = useGetGlobalWorkFlowsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const navigateToWorkflow = (id: number): void => {
    navigate(RoutePath.WorkflowTemplatePath.replace(':templateId', id.toString()));
  };

  useEffect(() => {
    dispatch(setHelmet({ title: t('workflowTemplatesTitle') }));
    dispatch(setBreadcrumbs([{ text: t('workflowTemplatesTitle') }]));
  }, []);

  useEffect(() => {
    if (isError) {
      setTimeout(() => navigate(-1), 2500);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      if (globalWorkflows.length !== 0) {
        const uniqueTeamIds = [] as number[];

        globalWorkflows.forEach((workflow) => {
          workflow.teamIds.forEach((teamId) => {
            if (!uniqueTeamIds.includes(teamId)) {
              uniqueTeamIds.push(teamId);
            }
          });
        });

        setTeamIds(uniqueTeamIds.toString());
      }
    }
  }, [isSuccess]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} className="mt-3">
        <MaterialTable
          icons={TableIcons}
          tableRef={tableRef}
          isLoading={isGettingWorkerFlows}
          options={{
            tableLayout: 'auto',
            addRowPosition: 'first',
            actionsColumnIndex: -1,
            actionsCellStyle: { padding: '0px 20px', textAlign: 'center' },
          }}
          actions={[
            {
              icon: Icons.KeyboardArrowRight,
              tooltip: t('navigateToWorkflow') as string,
              onClick: (_event, data) => {
                //@ts-ignore
                navigateToWorkflow(data.id);
              },
            },
          ]}
          columns={[
            { title: t('workflowId'), field: 'workflowId' },
            { title: t('name'), field: 'name' },
            { title: t('version'), field: 'workflowVersion' },
          ]}
          data={globalWorkflows}
          title={t('workflows')}
          detailPanel={(rowData) => {
            return (
              <Paper className="p-5 w-full">
                <TeamNameTable tableData={teamNames.filter((teamName) => rowData.rowData.teamIds.includes(teamName.id))} />
              </Paper>
            );
          }}
          onRowClick={(_event, _rowData, togglePanel) => {
            if (togglePanel) return togglePanel.toggleDetailPanel();
          }}
        />
      </Grid>
    </Grid>
  );
};

export default WorkflowTemplates;
