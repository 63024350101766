import { useGetWorkflowByIdQuery } from '@apis';
import { TableIcons } from '@components';
import { RoutePath } from '@constants';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@hooks';
import { WorkflowStepItem } from '@interfaces';
import MaterialTable from '@material-table/core';
import { Chip, Grid, Paper, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { setBreadcrumbs, setHelmet } from '@slices';
import { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const WorkflowTemplate = (): JSX.Element => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const parms = useParams();
  const templateId = parms?.templateId ?? '';
  const { workflow } = useAppSelector((x) => x.mahon);

  const { isLoading, isSuccess } = useGetWorkflowByIdQuery(parseInt(templateId), {
    refetchOnMountOrArgChange: true,
    skip: templateId === '',
  });

  useEffect(() => {
    if (isSuccess) {
      if (workflow) {
        dispatch(setBreadcrumbs([{ text: t('workflows'), link: RoutePath.WorkflowTemplatesPath }, { text: workflow.name }]));
      }
      dispatch(setHelmet({ title: t('htmlTitleWorkflow') }));
    }
  }, [isSuccess, workflow, templateId]);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MaterialTable
          icons={TableIcons}
          tableRef={tableRef}
          isLoading={isLoading}
          options={{
            tableLayout: 'auto',
            addRowPosition: 'first',
            actionsColumnIndex: -1,
          }}
          columns={[
            { title: t('stepIndex'), field: 'stepIndex' },
            { title: t('name'), field: 'name' },
            {
              title: t('authorOnly'),
              field: 'authorOnly',
              type: 'boolean',
              render: (rowData) =>
                rowData.authorOnly ? (
                  <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                ) : (
                  <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                ),
            },
          ]}
          data={workflow?.steps as WorkflowStepItem[]}
          title={t('worflowSteps')}
          detailPanel={(rowData) => {
            return (
              <Paper className="w-full p-5 space-y-4 mb-4">
                <Typography variant="subtitle1" gutterBottom>
                  {t('media')}
                </Typography>
                <div className="flex w-full flex-wrap flex-row gap-3">
                  {rowData.rowData.stepConfigurations.map((item, index) => {
                    return <Chip label={item.mediaConfiguration.description} key={`${index}`} />;
                  })}
                </div>
              </Paper>
            );
          }}
          onRowClick={(_event, _rowData, togglePanel) => {
            if (togglePanel) return togglePanel.toggleDetailPanel();
          }}
        />
      </Grid>
    </Grid>
  );
};

export default WorkflowTemplate;
