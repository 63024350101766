import React, { createRef, useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import { Fab, Grid, IconButton, InputAdornment, TablePagination, TextField, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AddTeamForm, TableIcons } from '@components';
import { tableConstants, RoutePath } from '@constants';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useNavigate } from 'react-router';
import { useGetOwnerTeamsQuery, useGetTeamsQuery } from '@apis';
import { useDebounce } from 'use-debounce';
import { setBreadcrumbs, setChangeActiveTeamStatus, setHelmet } from '@slices';
import { useAuth } from 'oidc-react';

const TeamList = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const tableRef = createRef();
  const [rowsPerPage, setRowsPerPage] = useState<number>(tableConstants.pageRowSize);
  const [page, setPage] = useState<number>(0);
  const [searchAutoFocus, setSearchAutoFocus] = useState<boolean>(false);
  const [dialogTeam, setDialogTeam] = useState<boolean>(false);
  const [searchTeam, setSearchTeam] = useState<string>('');
  const [debouncedSearchTeam] = useDebounce(searchTeam, 200);
  const navigate = useNavigate();
  const { totalResults, teams } = useAppSelector((state) => state.team);
  const { userRights } = useAppSelector((state) => state.app);
  const { userData } = useAuth();
  const { teamMemberships } = useAppSelector((state) => state.user);
  const { isLoading } = useGetTeamsQuery(
    { pageNo: page, pageSize: rowsPerPage, searchTerm: debouncedSearchTeam, parentTeamId: userRights.sysAdminTeams[0] },
    {
      refetchOnMountOrArgChange: true,
      skip: !(userData && userRights.isSysAdmin),
    },
  );
  const { isLoading: isOwnerTeamsLoading } = useGetOwnerTeamsQuery(
    { pageNo: page, pageSize: rowsPerPage, searchTerm: debouncedSearchTeam, parentTeamId: userRights.sysAdminTeams[0] },
    {
      refetchOnMountOrArgChange: true,
      skip: !(userData && !userRights.isSysAdmin),
    },
  );

  const navigateToTeam = (id: number, _name: string): void => {
    dispatch(setChangeActiveTeamStatus(true));
    navigate(RoutePath.TeamPath.replace(':teamId', id.toString()).replace(':tabName', ''));
  };

  useEffect(() => {
    if (debouncedSearchTeam !== null || debouncedSearchTeam !== undefined || debouncedSearchTeam !== '') {
      setPage(0);
    }
  }, [debouncedSearchTeam]);

  useEffect(() => {
    dispatch(setHelmet({ title: t('htmlTitleTeams') }));
    dispatch(setBreadcrumbs([{ text: t('teamsText') }]));
  }, []);

  const handleChangePage = (newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number): void => {
    setRowsPerPage(value);
    setPage(0);
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchTeam(e.target.value);
    setSearchAutoFocus(true);
  };

  const closeTeam = (): void => {
    setDialogTeam(false);
  };

  const buildTableAction = (rowData: any) => {
    const teamMembership = teamMemberships.find((x) => x.id == rowData.id);

    if (userRights.isSysAdmin || !teamMembership || !teamMembership.userMustEnableMfa) {
      return {
        icon: TableIcons.KeyboardArrowRight,
        tooltip: t('navigateToTeam'),
        onClick: (_event: any, rowData: any) => {
          navigateToTeam(rowData.id, rowData.name);
        },
        disabled: false,
      };
    }

    return {
      icon: TableIcons.LockIcon,
      tooltip: t('EnableMfaTooltip'),
      onClick: () => {
        navigate(RoutePath.UserMfaPath);
      },
      disabled: false,
    };
  };

  const tableComponents = {
    Groupbar: () => <div></div>,
    Pagination: (props: any) => (
      <TablePagination
        {...props}
        rowsPerPageOptions={tableConstants.rowsPerPageArrayOptions}
        rowsPerPage={rowsPerPage}
        count={totalResults}
        page={page}
        onPageChange={(_e, page) => handleChangePage(page)}
      />
    ),

    Action: (props: any) => {
      if (props.action.icon === 'search') {
        return (
          <div className="p-3 m-auto">
            <TextField
              variant="standard"
              id="search-customer-input"
              fullWidth={true}
              label=""
              placeholder={t('searchTeams') as string}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              onChange={(e) => handleSearchInput(e)}
              value={searchTeam}
              autoFocus={searchAutoFocus}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TableIcons.Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" disabled={!searchTeam} onClick={() => setSearchTeam('')} edge="end">
                      <TableIcons.ResetSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        );
      }
      if (props.action.icon === 'add') {
        return (
          <div className="p-3 m-auto">
            <Fab
              color="primary"
              size="medium"
              aria-label="add"
              className="!my-1 !mx-2 float-right !z-0"
              onClick={() => setDialogTeam(true)}
              disabled={isLoading || isOwnerTeamsLoading}>
              <TableIcons.Add />
            </Fab>
          </div>
        );
      }
      return (
        <Tooltip title={t('navigateToTeam')}>
          <IconButton className="!w-20 flex justify-center" onClick={() => navigateToTeam(props.data.id, props.data.name)}>
            <TableIcons.KeyboardArrowRight />
          </IconButton>
        </Tooltip>
      );
    },
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MaterialTable
          icons={TableIcons}
          tableRef={tableRef}
          isLoading={isLoading || isOwnerTeamsLoading}
          options={{
            sorting: false,
            actionsColumnIndex: -1,
            search: false,
            grouping: false,
            pageSize: rowsPerPage,
            pageSizeOptions: tableConstants.rowsPerPageArrayOptions,
            draggable: false,
          }}
          columns={[
            { title: t('id'), field: 'id' },
            { title: t('name'), field: 'name' },
            { title: t('owner'), field: 'owner' },
            {
              title: t('active'),
              field: 'active',
              type: 'boolean',
              align: 'center',
              render: (rowData) => (rowData.active ? <TableIcons.Tick /> : <TableIcons.Cross />),
            },
            { title: t('type'), field: 'type', defaultGroupOrder: -1 },
          ]}
          data={teams}
          title={t('teamsText')}
          //@ts-ignore
          actions={
            userRights.isSysAdmin
              ? [
                  {
                    icon: 'search',
                    isFreeAction: true,
                    onClick: (_event, _rowData) => {
                      //@ts-ignore
                      console.debug(_rowData.id);
                    },
                  },
                  {
                    icon: 'add',
                    isFreeAction: true,
                    onClick: (_event, _rowData) => {
                      //@ts-ignore
                      console.debug(_rowData.id);
                    },
                  },
                  (rowData) => buildTableAction(rowData),
                ]
              : [
                  {
                    icon: 'search',
                    isFreeAction: true,
                    onClick: (_event, _rowData) => {
                      //@ts-ignore
                      console.debug(_rowData.id);
                    },
                  },
                  (rowData) => buildTableAction(rowData),
                ]
          }
          onRowsPerPageChange={handleChangeRowsPerPage}
          components={tableComponents}
        />
      </Grid>
      <AddTeamForm closeDialog={closeTeam} isDialogOpen={dialogTeam} />
    </Grid>
  );
};

export default TeamList;
