import { useGetTeamQuery, useGetTeamServicesQuery, useGetTeamWorkFlowsQuery } from '@apis';
import { Icons, TableIcons } from '@components';
import { RoutePath } from '@constants';
import { useAppDispatch, useAppSelector } from '@hooks';
import MaterialTable from '@material-table/core';
import { Grid } from '@mui/material';
import { setBreadcrumbs, setTeamNavigationContext, setHelmet } from '@slices';
import React, { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const TeamWorkFlows = (): JSX.Element => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const parms = useParams();
  const teamId = parms?.teamId ?? '';
  const { team, teamServices } = useAppSelector((x) => x.team);
  const { workFlows } = useAppSelector((x) => x.mahon);

  useGetTeamQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  useGetTeamServicesQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  const {
    isLoading: isGettingWorkerFlows,
    isSuccess,
    isError,
  } = useGetTeamWorkFlowsQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  useEffect(() => {
    if (isSuccess) {
      if (team) {
        dispatch(setBreadcrumbs([{ text: t('teamPageTitle'), link: '/teams' }, { text: team.name, link: `/teams/${teamId}` }, { text: t('workflows') }]));
      }
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      setTimeout(() => navigate(-1), 2500);
    }
  }, [isError]);
  useEffect(() => {
    if (teamServices) {
      dispatch(setTeamNavigationContext({ teamServices, teamId, t }));
    }
  }, [teamServices]);

  useEffect(() => {
    if (team) {
      dispatch(setBreadcrumbs([{ text: t('teamPageTitle'), link: '/teams' }, { text: team.name, link: `/teams/${teamId}` }, { text: t('workflows') }]));
    }
    dispatch(setHelmet({ title: t('htmlTitleTeamWorkflows') }));
  }, [team]);
  const navigateToWorkflow = (id: number): void => {
    navigate(RoutePath.TeamWorkFlowPath.replace(':teamId', teamId).replace(':workflowId', id.toString()));
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} className="mt-3">
        <MaterialTable
          localization={{
            body: { emptyDataSourceMessage: t('teamNoWorkflows') },
          }}
          icons={TableIcons}
          tableRef={tableRef}
          isLoading={isGettingWorkerFlows}
          options={{
            tableLayout: 'auto',
            addRowPosition: 'first',
            actionsColumnIndex: -1,
            actionsCellStyle: { padding: '0px 20px', textAlign: 'center' },
          }}
          actions={[
            {
              icon: Icons.KeyboardArrowRight,
              tooltip: t('navigateToWorkflow') as string,
              onClick: (_event, data) => {
                //@ts-ignore
                navigateToWorkflow(data.workflowId);
              },
            },
          ]}
          columns={[
            { title: t('id'), field: 'workflowId' },
            { title: t('name'), field: 'name' },
            { title: t('version'), field: 'workflowVersion' },
          ]}
          data={workFlows}
          title={t('workflows')}
        />
      </Grid>
    </Grid>
  );
};

export default TeamWorkFlows;
