import { TeamNameDto } from '@interfaces';
import MaterialTable from '@material-table/core';
import { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableIcons, Icons } from '../shared';
import { RoutePath } from '@constants';
import { useNavigate } from 'react-router';

type Props = {
  tableData: TeamNameDto[];
};

const TeamNameTable = ({ tableData }: Props): JSX.Element => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  const navigate = useNavigate();

  const navigateToTeam = (id: number, _name: string): void => {
    navigate(RoutePath.TeamPath.replace(':teamId', id.toString()).replace(':tabName', ''));
  };

  return (
    <MaterialTable
      icons={TableIcons}
      tableRef={tableRef}
      options={{
        tableLayout: 'auto',
        addRowPosition: 'first',
        actionsColumnIndex: -1,
        actionsCellStyle: { padding: '0px 20px', textAlign: 'center' },
      }}
      actions={[
        {
          icon: Icons.KeyboardArrowRight,
          tooltip: t('navigateToTeam') as string,
          onClick: (_event, data) => {
            //@ts-ignore
            navigateToTeam(data.id);
          },
        },
      ]}
      columns={[
        { title: t('id'), field: 'id' },
        { title: t('name'), field: 'name' },
      ]}
      data={tableData}
      title={t('teams')}
    />
  );
};

export default TeamNameTable;
