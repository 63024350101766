import {
  useCheckRevokeUserLicenceMutation,
  useGetLicencesQuery,
  useGetTeamMembersSearchQuery,
  usePostUserLicenceMutation,
  useRevokeUserLicenceMutation,
} from '@apis';
import { useAppDispatch, useAppSelector } from '@hooks';
import { IdAndNameItem, LicenceItem } from '@interfaces';
import MaterialTable from '@material-table/core';
import {
  InputAdornment,
  Grid,
  Typography,
  IconButton,
  Paper,
  TextField,
  Switch,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
} from '@mui/material';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableIcons } from '../shared';
import { useDebounce } from 'use-debounce';
import { openSnackbar } from '@slices';
import { PasswordDialog } from '../ui';
import { green, red } from '@constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';

type Props = {
  teamId: number;
};

type RevokeRolesItem = {
  teamLicenceId: number;
  userId: number;
  roles: IdAndNameItem[];
};

const TeamLicences = ({ teamId }: Props): JSX.Element => {
  const { isSuccess, isLoading, refetch } = useGetLicencesQuery(teamId, { skip: !teamId, refetchOnMountOrArgChange: true });
  const { licences } = useAppSelector((x) => x.licence);
  const { userValidation } = useAppSelector((x) => x.user);
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const { teamMembers } = useAppSelector((x) => x.team);
  const tableRefMembers = createRef();
  const tableRefLicences = createRef();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [focus, setFocus] = useState<boolean>(false);
  const [debouncedSearchMember] = useDebounce(searchTerm, 200);
  const [checkRevokeUserLicence, { isLoading: isCheckRevokeLicence }] = useCheckRevokeUserLicenceMutation();
  const [consumeUserLicence, { isLoading: isConsumeLicence }] = usePostUserLicenceMutation();
  const [revokeUserLicence, { isLoading: isRevokingLicence }] = useRevokeUserLicenceMutation();
  const [showRolesToBeRemoved, setShowRolesToBeRemoved] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rolesToBeRemoved, setRolesToBeRemoved] = useState<RevokeRolesItem>({ userId: 0, teamLicenceId: 0, roles: [] });
  const [isPasswordDialogOpen, setPasswordIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess) {
      if (licences.length == 4) console.warn('4');
    }
  }, [isSuccess]);

  const { isLoading: isGettingMembers } = useGetTeamMembersSearchQuery(
    { teamId, searchTerm: debouncedSearchMember },
    { refetchOnMountOrArgChange: true, skip: teamId < 1 },
  );

  const tableOptions = {
    sorting: false,
    actionsColumnIndex: -1,
    search: false,
    toolbar: true,
    pageSize: 5,
    draggable: false,
    filtering: false,
  };

  const tableComponents = {
    Action: (actionProps: any) => {
      if (actionProps.action.icon === 'search') {
        return (
          <div className="p-3 m-auto">
            <TextField
              variant="standard"
              id="search-member-input"
              fullWidth={true}
              label=""
              autoFocus={focus}
              placeholder={t('TeamEditSearchMemberHintText') as string}
              onChange={(e) => {
                setSearchTerm(e.currentTarget.value);
                setFocus(true);
              }}
              value={searchTerm}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TableIcons.Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" disabled={!searchTerm} onClick={() => setSearchTerm('')} edge="end">
                      <TableIcons.ResetSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        );
      }
      return <React.Fragment />;
    },
  };
  const handleUserLicence = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean, userId: number, teamLicenceId: number): void => {
    if (checked) {
      consumeUserLicence({ teamId, teamLicenceId, userId })
        .unwrap()
        .then(() => {
          refetch();
          dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        })
        .catch((error) => {
          console.debug('Failed while attempting to consume user licence', error);
          return;
        });
    } else {
      checkRevokeUserLicence({ teamId, teamLicenceId, userId })
        .unwrap()
        .then((data) => {
          if (data.length > 0) {
            setRolesToBeRemoved({ teamLicenceId, userId, roles: data });
            setDialogOpen(true);
            setShowRolesToBeRemoved(true);
          } else {
            handleRevokeUserLicence(undefined, teamLicenceId, userId);
          }
        })
        .catch((error) => {
          console.debug('Failed while attempting to check revoke licence', error);
          return;
        });
    }
  };
  const handleRevokeUserLicence = (_value?: string, teamLicenceId?: number, userId?: number): void => {
    revokeUserLicence({ teamId, teamLicenceId: teamLicenceId ?? rolesToBeRemoved.teamLicenceId, userId: userId ?? rolesToBeRemoved.userId })
      .unwrap()
      .then(() => {
        refetch();
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
      })
      .catch((error) => {
        console.debug('Failed while attempting to revoke user licence', error);
        return;
      });
  };

  const onClose = (): void => {
    setDialogOpen(false);
  };

  const validatePasswordToken = (): void => {
    onClose();
    let dateNow = new Date();
    let dateToken = userValidation ? new Date(userValidation.validUntil) : null;

    if (!dateToken || dateNow > dateToken) {
      setPasswordIsDialogOpen(true);
    } else {
      handleRevokeUserLicence(userValidation?.token);
    }
  };

  return (
    <React.Fragment>
      <Paper className="w-full p-5 space-y-4" square>
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={9} md={9} lg={10}>
            <Typography variant="h6" gutterBottom>
              {t('teamLicencesText')}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              {t('teamLicencesSubText')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MaterialTable
            icons={TableIcons}
            tableRef={tableRefLicences}
            options={{
              sorting: false,
              actionsColumnIndex: -1,
              search: true,
              toolbar: true,
              pageSize: 5,
              draggable: false,
              filtering: false,
              emptyRowsWhenPaging: false,
            }}
            title={t('activeLicences')}
            columns={[
              { title: t('name'), field: 'serviceName' },
              {
                title: t('countConsumedTotal'),
                align: 'center',
                field: 'count',
                render: (rowData: LicenceItem) =>
                  (rowData.trial && rowData.count === 0) || rowData.unlimited ? (
                    <strong>{rowData.userLicences.length}/∞</strong>
                  ) : (
                    <strong>
                      {rowData.userLicences.length}/{rowData.count}
                    </strong>
                  ),
              },
              {
                title: t('expirationDate'),
                align: 'left',
                field: 'endDate',
                type: 'date',
                render: (rowData) =>
                  rowData.trial
                    ? rowData.trialEnd
                      ? moment(rowData.trialEnd).format('DD MMM YYYY')
                      : t('N/A')
                    : rowData.endDate
                    ? moment(rowData.endDate).format('DD MMM YYYY')
                    : t('N/A'),
              },
              {
                title: t('trial'),
                align: 'left',
                field: 'trial',
                render: (rowData) =>
                  rowData.trial ? (
                    <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                  ),
              },
            ]}
            data={licences}
            isLoading={isLoading}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MaterialTable
            icons={TableIcons}
            tableRef={tableRefMembers}
            options={tableOptions}
            title={t('teamMembersText')}
            columns={[{ title: t('name'), field: 'fullname' }]}
            data={teamMembers}
            isLoading={isGettingMembers}
            components={tableComponents}
            actions={[
              {
                icon: 'search',
                isFreeAction: true,
                onClick: (_event, _rowData) => {
                  return;
                },
              },
            ]}
            detailPanel={(rowData) => {
              return (
                <Paper className="w-full p-5 space-y-4 mb-4">
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MaterialTable
                      icons={TableIcons}
                      options={{
                        sorting: false,
                        actionsColumnIndex: -1,
                        search: true,
                        toolbar: true,
                        pageSize: 5,
                        draggable: false,
                        filtering: false,
                        emptyRowsWhenPaging: false,
                      }}
                      title={t('userLicences')}
                      columns={[
                        { title: t('name'), field: 'serviceName' },
                        {
                          title: t('consumeRevoke'),
                          align: 'center',
                          field: 'count',
                          render: (rowDataLicence: LicenceItem) => (
                            <Switch
                              checked={rowDataLicence.userLicences.find((x) => x.userId === rowData.rowData.id) ? true : false}
                              disabled={
                                (rowDataLicence.userLicences.find((x) => x.userId === rowData.rowData.id) === undefined &&
                                  rowDataLicence.userLicences.length + 1 > rowDataLicence.count) ||
                                isRevokingLicence ||
                                isCheckRevokeLicence ||
                                isConsumeLicence
                              }
                              onChange={(e, checked) => handleUserLicence(e, checked, rowData.rowData.id, rowDataLicence.id)}
                              color="primary"
                              name="consume-licence"
                            />
                          ),
                        },
                      ]}
                      data={licences}
                      isLoading={isLoading}
                    />
                  </Grid>
                </Paper>
              );
            }}
            onRowClick={(_event, _rowData, togglePanel) => {
              if (togglePanel) return togglePanel.toggleDetailPanel();
            }}
          />
        </Grid>
      </Paper>
      <Dialog open={dialogOpen} onClose={onClose} fullWidth={true}>
        {showRolesToBeRemoved ? (
          <React.Fragment>
            <DialogTitle id="dialog-title">
              <Typography variant="h6" gutterBottom>
                {t('removeRolesTitle')}
              </Typography>

              <Typography variant="subtitle1" gutterBottom>
                {t('removeRolesText')}
              </Typography>
            </DialogTitle>

            <DialogContent>
              <div className="flex w-full flex-wrap flex-row gap-3">
                {rolesToBeRemoved?.roles.map((item, index) => {
                  return <Chip label={item.name} key={`${index}`} />;
                })}
              </div>
            </DialogContent>

            <DialogActions>
              <Button variant="contained" color="secondary" onClick={validatePasswordToken} disabled={isRevokingLicence}>
                {isRevokingLicence && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                {t('removeButton')}
              </Button>
              <Button variant="contained" color="primary" onClick={onClose} disabled={isRevokingLicence}>
                {t('keepButton')}
              </Button>
            </DialogActions>
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Dialog>
      <PasswordDialog setOpen={setPasswordIsDialogOpen} open={isPasswordDialogOpen} onSuccessAction={handleRevokeUserLicence} navigateOnClose={false} />
    </React.Fragment>
  );
};
export default TeamLicences;
