import {
  useGetTeamQuery,
  useGetTeamServicesQuery,
  useGetTeamWorflowFlagQuery,
  useGetTeamWorkFlowStepFiltersQuery,
  useGetTeamWorkFlowsQuery,
  useGetTeamWorkerPoolsQuery,
  usePostWorkflowFiltersMutation,
  usePutWorkflowFlagMutation,
} from '@apis';
import { TableIcons, WorkflowStepFiltersEditor } from '@components';
import { RoutePath } from '@constants';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@hooks';
import { WorkflowStepFilterItemTableRow, WorkflowStepItem } from '@interfaces';
import MaterialTable from '@material-table/core';
import { Button, CircularProgress, FormControlLabel, FormGroup, Grid, Paper, Switch, Typography } from '@mui/material';
import { green, red } from '@constants';
import { setBreadcrumbs, setTeamNavigationContext, setHelmet, openSnackbar } from '@slices';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

const TeamWorkflow = () => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const parms = useParams();
  const teamId = parms?.teamId ?? '';
  const workflowId = parms?.workflowId ?? '';
  const { team, teamServices } = useAppSelector((x) => x.team);
  const { workFlows, workflowStepFilters, workflowFlag } = useAppSelector((x) => x.mahon);
  const [tableData, setTableData] = useState<WorkflowStepItem[]>([]);
  const [postWorflowStepFilters, { isLoading: isUpdatingFilters }] = usePostWorkflowFiltersMutation();
  const [putWorkflowFlag] = usePutWorkflowFlagMutation();
  const [tableFiltersData, setTableFiltersData] = useState<WorkflowStepFilterItemTableRow[]>([]);
  const [stepsIds, setStepsIds] = useState<number[]>([]);
  const [rowCheck, setRowCheck] = useState<boolean>(false);

  useGetTeamQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });

  useGetTeamServicesQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });

  const { refetch: refetchGetWorkflowFlag } = useGetTeamWorflowFlagQuery(
    { teamId: parseInt(teamId), workflowId: parseInt(workflowId) },
    {
      refetchOnMountOrArgChange: true,
      skip: workflowId === '',
    },
  );

  const {
    isLoading: isGettingWorkerFlows,
    isSuccess,
    isError,
  } = useGetTeamWorkFlowsQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });

  useGetTeamWorkerPoolsQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });

  const {
    refetch,
    isLoading: isGettingFilters,
    isSuccess: isGettingFiltersSuccess,
  } = useGetTeamWorkFlowStepFiltersQuery({ teamId: parseInt(teamId), stepIds: stepsIds }, { refetchOnMountOrArgChange: true, skip: stepsIds.length === 0 });

  const onSubmit = () => {
    const groupByStep = {} as any;
    tableData.map((item) => {
      groupByStep[item.name] = tableFiltersData.filter((x) => x.workflowStepId === item.id);
    });
    for (const step in groupByStep) {
      if (groupByStep[step].length === 0) {
        dispatch(openSnackbar({ message: `${t('atLeastOneStepFilterRequiredForStep')} ${step}`, severity: 'error', display: true }));
        return;
      }
    }

    postWorflowStepFilters({
      data: tableFiltersData,
      teamId: parseInt(teamId),
      workflowId: parseInt(workflowId),
    })
      .unwrap()
      .then((data) => {
        dispatch(
          openSnackbar({
            message: `${t('changesStepFiltersSaved')} ${data.affectedJobCount} ${t('jobsThatMightBeAffected')}`,
            severity: 'success',
            display: true,
          }),
        );
        refetch();
      })
      .catch((err) => {
        console.debug('Failed while attempting to update team worflow step filters', err);
        return;
      });
  };

  const onReset = (): void => {
    refetch();
  };

  useEffect(() => {
    if (workflowStepFilters) {
      setTableFiltersData([
        ...workflowStepFilters.map((item) => {
          return {
            ...item,
            rowId: uuidv4(),
          };
        }),
      ]);
    }
  }, [workflowStepFilters]);

  useEffect(() => {
    if (!rowCheck && isGettingFiltersSuccess) setRowCheck(true);
  }, [tableFiltersData]);

  useEffect(() => {
    if (isSuccess) {
      if (team && workFlows.length !== 0) {
        dispatch(
          setBreadcrumbs([
            { text: t('teamPageTitle'), link: '/teams' },
            { text: team.name, link: `/teams/${teamId}` },
            { text: t('workflows'), link: RoutePath.TeamFlowsPath.replace(':teamId', teamId) },
            { text: workFlows.filter((x) => x.workflowId === parseInt(workflowId))[0].name },
          ]),
        );
      }
      if (workflowId) {
        setTableData(workFlows.filter((x) => x.workflowId === parseInt(workflowId))[0].steps);
        setStepsIds(workFlows.filter((x) => x.workflowId === parseInt(workflowId))[0].steps.map((x) => x.id));
      }
      dispatch(setHelmet({ title: t('htmlTitleTeamWorkflow') }));
    }
  }, [isSuccess, workFlows, workflowId]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => navigate(-1), 2500);
    }
  }, [isError]);

  useEffect(() => {
    if (teamServices) {
      dispatch(setTeamNavigationContext({ teamServices, teamId, t }));
    }
  }, [teamServices]);

  const handleWorkFlowFlagChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    putWorkflowFlag({
      flagValue: checked,
      teamId: parseInt(teamId),
      workflowId: parseInt(workflowId),
    })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        refetchGetWorkflowFlag();
      })
      .catch((err) => {
        console.debug('Failed while attempting to set workflow flag', err);
        return;
      });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} className="pb-5">
        <Typography variant="h6" gutterBottom>
          {t('teamWorkflowPageTitle')}
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          {t('teamWorkflowPageSubtitle')}
        </Typography>
      </Grid>
      <Paper className="p-5 w-full space-y-6">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch className="float-right" checked={workflowFlag} onChange={handleWorkFlowFlagChange} color="primary" name="allowStepFilterOverride" />
              }
              label={t('allowStepFilterOverrideLabel')}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MaterialTable
            icons={TableIcons}
            tableRef={tableRef}
            isLoading={isGettingWorkerFlows || isGettingFilters}
            options={{
              tableLayout: 'auto',
              addRowPosition: 'first',
              actionsColumnIndex: -1,
              rowStyle: (rowData) => ({
                backgroundColor:
                  isGettingFiltersSuccess && rowCheck && tableFiltersData.filter((x) => x.workflowStepId === rowData.id).length === 0 ? red[500] : '',
              }),
            }}
            columns={[
              { title: t('stepIndex'), field: 'stepIndex' },
              { title: t('name'), field: 'name' },
              {
                title: t('authorOnly'),
                field: 'authorOnly',
                type: 'boolean',
                render: (rowData) =>
                  rowData.authorOnly ? (
                    <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                  ),
              },
            ]}
            data={tableData}
            title={t('worflowSteps')}
            detailPanel={(rowData) => {
              return (
                <Paper className="p-5 w-full">
                  <WorkflowStepFiltersEditor
                    worflowStepId={rowData.rowData.id}
                    teamId={parseInt(teamId)}
                    workflowId={parseInt(workflowId)}
                    tableData={tableFiltersData}
                    setTableData={setTableFiltersData}
                  />
                </Paper>
              );
            }}
            onRowClick={(_event, _rowData, togglePanel) => {
              if (togglePanel) return togglePanel.toggleDetailPanel();
            }}
          />
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} className="justify-end">
          <Button variant="contained" color="secondary" className="!mr-5" disabled={isUpdatingFilters} onClick={onReset}>
            {isUpdatingFilters && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('cancelButtonText')}
          </Button>

          <Button variant="contained" color="primary" disabled={isUpdatingFilters} onClick={onSubmit}>
            {isUpdatingFilters && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('submitButtonText')}
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default TeamWorkflow;
