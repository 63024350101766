export const red = {
  500: '#C93131',
};

export const green = {
  500: '#53B216',
};
export const blue = {
  500: '#0077C8',
};
export const white = {
  500: '#fff',
};
