import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowProgress,
  faChartNetwork,
  faCogs,
  faCommentLines,
  faExchangeAlt,
  faHouseUser,
  faQuestionCircle,
  faUsers,
  faUsersClass,
} from '@fortawesome/pro-regular-svg-icons';
import { white } from '@constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RoutePath } from '@constants';
import { useGetTeamMembershipsQuery } from '@apis';
import { useAuth } from 'oidc-react';

const NavBarSideMenu = (): JSX.Element => {
  const { userData } = useAuth();
  const { teamMemberships } = useAppSelector((state) => state.user);
  const { t } = useTranslation('pano');
  const navigate = useNavigate();
  const location = useLocation();
  const { userRights, activeTeam, actionsList, activeTeamContextTitle, navContext, permissionData, sideMenuDrawOpenStatus } = useAppSelector(
    (state) => state.app,
  );
  const [navigationContext, setNavigationContext] = useState<JSX.Element[]>([]);
  const [multipleTeams, setMultipleTeams] = useState<boolean>(false);
  useGetTeamMembershipsQuery(undefined, { skip: !userData });

  const checkMultipleTeams = (): void => {
    if (userRights.ownedTeams.length + userRights.administeredTeams.length > 1) {
      setMultipleTeams(true);
    } else {
      setMultipleTeams(false);
    }
  };

  const navigateTeam = (id: number): void => {
    const team = teamMemberships.find((tm) => tm.id === id);
    if (team?.userMustEnableMfa) {
      if (location.pathname.toLocaleLowerCase() !== `/user/security/two-step-authentication/${team.id}`) {
        navigate(`/user/security/two-step-authentication/${team.id}`);
      }
    } else {
      if (location.pathname.toLocaleLowerCase() !== `/teams/${id}`) {
        navigate(`/teams/${id}`);
      }
    }
  };

  const navigateToLink = (link: string) => {
    if (location.pathname.toLocaleLowerCase() !== link.toLocaleLowerCase()) {
      navigate(link);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    checkMultipleTeams();
  }, [teamMemberships, userRights]);

  useEffect(() => {
    let navBuilder = [] as JSX.Element[];

    if (navContext != null) {
      let header = null;
      if (activeTeamContextTitle && navContext.length && sideMenuDrawOpenStatus) {
        navBuilder.push(
          <ListSubheader key={activeTeamContextTitle}>
            <Typography component="span" variant="h6" sx={{ color: 'common.white' }}>
              {activeTeamContextTitle}
            </Typography>
          </ListSubheader>,
        );
      }

      for (let i = 0, l = navContext.length; i < l; i++) {
        let currentItem = navContext[i];

        if (currentItem.header !== header && sideMenuDrawOpenStatus) {
          header = currentItem.header;

          navBuilder.push(
            <ListSubheader inset={true} key={header}>
              {header}
            </ListSubheader>,
          );
        }

        currentItem.links.forEach((link) => {
          navBuilder.push(
            <React.Fragment key={link.key}>
              <Tooltip title={link.tooltip}>
                <ListItemButton onClick={() => navigateToLink(link.link)}>
                  <ListItemIcon>
                    {link.icon ? link.icon : <FontAwesomeIcon icon={faQuestionCircle} size="lg" style={{ color: white[500] }} fixedWidth />}
                  </ListItemIcon>
                  <ListItemText primary={link.text}></ListItemText>
                </ListItemButton>
              </Tooltip>
            </React.Fragment>,
          );
        });
      }
    }

    setNavigationContext(navBuilder);
  }, [navContext, sideMenuDrawOpenStatus]);

  const activeTeamTooltip = activeTeam ? activeTeam.name : t('useListText');

  const activeTeamPrimaryText = activeTeam ? t('activeTeam') : t('selectActiveTeam');

  const activeTeamSecondaryText = activeTeam ? activeTeam.name : t('useListText');

  const activeTeamArea = (
    <React.Fragment>
      <Tooltip title={activeTeamTooltip}>
        {activeTeam ? (
          <ListItemButton alignItems="flex-start" onClick={() => navigateTeam(activeTeam.id)}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faHouseUser} size="lg" style={{ color: white[500], marginTop: '10px' }} fixedWidth />
            </ListItemIcon>

            <ListItemText
              primary={activeTeamPrimaryText}
              secondary={
                <React.Fragment>
                  <Typography component="span" variant="body2" sx={{ color: 'common.white' }}>
                    {activeTeamSecondaryText}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItemButton>
        ) : (
          <ListItem alignItems="flex-start">
            <ListItemIcon>
              <FontAwesomeIcon icon={faHouseUser} size="lg" style={{ color: white[500] }} fixedWidth />
            </ListItemIcon>

            <ListItemText
              primary={activeTeamPrimaryText}
              secondary={
                <React.Fragment>
                  <Typography component="span" variant="body2" sx={{ color: 'common.white' }}>
                    {activeTeamSecondaryText}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        )}
      </Tooltip>
    </React.Fragment>
  );
  return (
    <>
      {userData ? (
        <>
          <List>
            {userRights.isSysAdmin || userRights.isTeamOwner || userRights.isTeamAdmin ? (
              <React.Fragment>
                {activeTeamArea}
                <Divider />
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {userRights.isSysAdmin || (userRights.isTeamOwner && multipleTeams) || (userRights.isTeamAdmin && multipleTeams) ? (
              <Tooltip title={t('searchTeams')}>
                <ListItemButton onClick={() => navigateToLink(RoutePath.TeamsPath)}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faUsersClass} style={{ color: white[500] }} size="lg" fixedWidth />
                  </ListItemIcon>
                  <ListItemText primary={t('teamPageTitle')} />
                </ListItemButton>
              </Tooltip>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {userRights.isSysAdmin ? (
              <React.Fragment>
                <Tooltip title={t('searchUsers')}>
                  <ListItemButton onClick={() => navigateToLink(RoutePath.UsersPath)}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faUsers} style={{ color: white[500] }} size="lg" fixedWidth />
                    </ListItemIcon>
                    <ListItemText primary={t('userPageTitle')} />
                  </ListItemButton>
                </Tooltip>

                <Tooltip title={t('echoCommandsButtonGlobal')}>
                  <ListItemButton onClick={() => navigateToLink(RoutePath.AdminCommandsPath)}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faCogs} style={{ color: white[500] }} size="lg" fixedWidth />
                    </ListItemIcon>
                    <ListItemText primary={t('echoCommandsButtonGlobal')} />
                  </ListItemButton>
                </Tooltip>

                <Tooltip title={t('echoFeedbackButtonGlobal')}>
                  <ListItemButton onClick={() => navigateToLink(RoutePath.AdminFeedbackPath)}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faCommentLines} style={{ color: white[500] }} size="lg" fixedWidth />
                    </ListItemIcon>
                    <ListItemText primary={t('echoFeedbackButtonGlobal')} />
                  </ListItemButton>
                </Tooltip>

                <Tooltip title={t('substitutionsTooltip')}>
                  <ListItemButton onClick={() => navigateToLink(RoutePath.SubstitutionsPath)}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faExchangeAlt} style={{ color: white[500] }} size="lg" fixedWidth />
                    </ListItemIcon>
                    <ListItemText primary={t('substitutionsTitle')} />
                  </ListItemButton>
                </Tooltip>

                <Tooltip title={t('workflowTemplatesTooltip')}>
                  <ListItemButton onClick={() => navigateToLink(RoutePath.WorkflowTemplatesPath)}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faArrowProgress} style={{ color: white[500] }} size="lg" fixedWidth />
                    </ListItemIcon>
                    <ListItemText primary={t('workflowTemplatesTitle')} />
                  </ListItemButton>
                </Tooltip>
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {'pano-role.read' in permissionData ? (
              <React.Fragment>
                <Tooltip title={t('searchRoles')}>
                  <ListItemButton onClick={() => navigateToLink(RoutePath.RolesPath)}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faChartNetwork} style={{ color: white[500] }} size="lg" fixedWidth />
                    </ListItemIcon>
                    <ListItemText primary={t('roles')} />
                  </ListItemButton>
                </Tooltip>
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </List>

          {userRights.isSysAdmin || (userRights.isTeamOwner && multipleTeams) || (userRights.isTeamAdmin && multipleTeams) ? <Divider /> : <></>}

          <List>{navigationContext}</List>
          {navigationContext.length > 0 && <Divider />}
          {actionsList}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default NavBarSideMenu;
