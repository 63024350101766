import { ThemeOptions } from '@mui/material';

export const LEXACOM_LIGHT_THEME = {
  palette: {
    mode: 'light',
    primary: {
      main: '#52b216',
      light: '#75c145',
      dark: '#4aa014',
      contrastText: '#fff',
    },

    secondary: {
      main: '#c93131',
      light: '#d45a5a',
      dark: '#b52c2c',
      contrastText: '#fff',
    },
    info: { main: '#0078c8', light: '#3393d3', dark: '#006cb4', contrastText: '#fff' },

    background: {
      default: '#fff',
      paper: '#f1f1f1',
    },

    text: {
      primary: '#121212',
      secondary: '#333333',
    },
  },
  custom: {
    appBarBackground: '#0078c8',
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#fff',
          color: '#121212',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#0078c8',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#006cb4',
          color: '#fff',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#fff',
        },
      },
    },

    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: '#006cb4',
          color: '#fff',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#0078c8',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#0078c8',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#0078c8',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0078c8',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:after': {
            borderBottomColor: '#0078c8',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#3393d3 !important',
          color: '#fff',
        },
      },
    },
  },
} as ThemeOptions;

export const LEXACOM_DARK_THEME = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#4aa014',
      light: '#75c145',
      dark: '#52b216',
      contrastText: '#fff',
    },

    secondary: {
      main: '#b52c2c',
      light: '#d45a5a',
      dark: '#c93131',
      contrastText: '#fff',
    },
    info: { main: '#0078c8', light: '#3393d3', dark: '#006cb4', contrastText: '#000000' },

    background: {
      default: '#192023', // Very dark grey
      paper: '#222b30',
    },
    text: {
      primary: '#ffffff',
      secondary: '#f1f1f1',
    },
  },
  custom: {
    appBarBackground: '#222b30',
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
      color: '#fff',
    },
  },
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#0078c8',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#192023',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          background: '#121212',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#0078c8',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#0078c8',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#0078c8',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0078c8',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:after': {
            borderBottomColor: '#0078c8',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#0078c8',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#192023',
          color: '#ffffff',
        },
      },
    },
  },
} as ThemeOptions;
