export enum RoutePath {
  NotFound404 = '*',
  Unauthorized401 = '/Unauthorized',
  Home = '/',
  Auth = '/authorize',
  MfaDialogPath = '/mfa-check',
  UserMfaPath = '/user/security/two-step-authentication/',
  UserMfaPathTeamRedirect = '/user/security/two-step-authentication/:teamId',
  UserMfaRecoveryPath = '/user/security/two-step-authentication?recovery=true',
  UserChangePasswordPath = '/user/changePassword',
  UserConfirmPath = '/user/:userId/confirm',
  UserCommandsPath = '/user/commands',
  UserPath = '/users/:userId',
  UsersPath = '/users',
  UserAccountPath = '/user',
  UserPasswordValidation = '/user/pass-check',
  AdminCommandsPath = '/dashboard/commands',
  AdminFeedbackPath = '/dashboard/feedback',
  TeamCommandsPath = '/teams/:teamId/services/commands',
  TeamRolePath = '/teams/:teamId/roles/:roleId',
  TeamConfigPath = '/teams/:teamId/services/configuration',
  TeamEchoConfigPath = '/teams/:teamId/services/echo-configuration',
  TeamRulesPath = '/teams/:teamId/services/workerpools',
  TeamPath = '/teams/:teamId/:tabName?',
  TeamsPath = '/teams',
  TeamDashboardPath = '/teamadmin/dashboard',
  RolesPath = '/roles',
  TeamFlowsPath = '/teams/:teamId/services/workflows',
  TeamWorkFlowPath = '/teams/:teamId/services/workflows/:workflowId',
  SubstitutionsPath = '/substitutions',
  SubstitutionPath = '/substitutions/:substitutionId',
  WorkflowTemplatesPath = '/workflowtemplates',
  WorkflowTemplatePath = '/workflowtemplates/:templateId',
}
