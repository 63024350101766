import React, { createRef } from 'react';
import { TableIcons } from '../shared';
import MaterialTable from '@material-table/core';
import { usePostWorkerPoolMutation, usePutWorkerPoolsMutation, useGetWorkerPoolCategoriesQuery } from '@apis';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks';
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { red, green } from '@constants';
import { WorkerPoolItem } from '@interfaces';
import { openSnackbar } from '@slices';
import { Autocomplete, TextField } from '@mui/material';

type Props = {
  refetch: () => void;
  isLoading?: boolean;
  teamId: number;
};

const WorkerPoolEditor = ({ refetch, isLoading, teamId }: Props): JSX.Element => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  useGetWorkerPoolCategoriesQuery(teamId, { refetchOnMountOrArgChange: true });
  const [putWorkerPools] = usePutWorkerPoolsMutation();
  const [postWorkerPool] = usePostWorkerPoolMutation();
  const { workerPools, workerPoolCategories } = useAppSelector((x) => x.mahon);
  const dispatch = useAppDispatch();
  const categoriesLookup: any = {};
  const getCategoriesLookup = (): any => {
    workerPoolCategories.map((item) => {
      categoriesLookup[item.id] = item.name;
    });
    return categoriesLookup;
  };

  return (
    <MaterialTable
      tableRef={tableRef}
      icons={TableIcons}
      isLoading={isLoading}
      options={{
        tableLayout: 'auto',
        addRowPosition: 'first',
        sorting: false,
        filtering: false,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        actionsCellStyle: { padding: '0px 20px', textAlign: 'center' },
      }}
      columns={[
        { title: t('name'), field: 'name' },
        { title: t('description'), field: 'description' },
        {
          title: t('locked'),
          field: 'locked',
          align: 'center',
          type: 'boolean',
          width: '150px',
          render: (rowData) =>
            rowData.locked ? <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} /> : <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />,
        },
        {
          title: t('category'),
          field: 'category',
          lookup: getCategoriesLookup(),
          editComponent: (props) => (
            <Autocomplete
              options={workerPoolCategories}
              getOptionLabel={(option) => option.name}
              autoComplete={false}
              value={workerPoolCategories.find((x) => x.id === props.value) ?? null}
              onChange={(_event, value) => props.onChange(value?.id)}
              renderInput={(params) => <TextField {...params} label={t('categories')} fullWidth />}
            />
          ),
        },
      ]}
      data={workerPools}
      title={t('workerPools')}
      editable={{
        onRowAdd: (newData) =>
          new Promise<void>((resolve, reject) => {
            if (!newData.name) {
              dispatch(openSnackbar({ message: `${t('name')} ${t('fieldRequired')}`, severity: 'error', display: true }));
              reject();
            } else if (workerPools.find((x) => x.name.trim() === newData.name.trim())) {
              dispatch(openSnackbar({ message: `${t('nameAlreadyExists')}`, severity: 'error', display: true }));
              reject();
            } else if (!newData.description) {
              dispatch(openSnackbar({ message: `${t('description')} ${t('fieldRequired')}`, severity: 'error', display: true }));
              reject();
            } else if (!newData.category) {
              dispatch(openSnackbar({ message: `${t('category')} ${t('fieldRequired')}`, severity: 'error', display: true }));
              reject();
            } else {
              postWorkerPool({
                data: newData,
                teamId,
              })
                .then(() => {
                  refetch();
                  dispatch(openSnackbar({ message: t('workerPoolAdded'), severity: 'success', display: true }));
                  resolve();
                })
                .catch((err) => {
                  console.debug('Failed while attempting to add worker pool', err);
                  reject();
                });
            }
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise<void>((resolve, reject) => {
            if (!newData.name) {
              dispatch(openSnackbar({ message: `${t('name')} ${t('fieldRequired')}`, severity: 'error', display: true }));
              reject();
            } else if (newData.name.trim() !== oldData?.name && workerPools.find((x) => x.name.trim() === newData.name.trim())) {
              dispatch(openSnackbar({ message: `${t('nameAlreadyExists')}`, severity: 'error', display: true }));
              reject();
            } else if (!newData.description) {
              dispatch(openSnackbar({ message: `${t('description')} ${t('fieldRequired')}`, severity: 'error', display: true }));
              reject();
            } else if (!newData.category) {
              dispatch(openSnackbar({ message: `${t('category')} ${t('fieldRequired')}`, severity: 'error', display: true }));
              reject();
            } else {
              const data = [] as WorkerPoolItem[];
              data.push({
                ...newData,
                //@ts-ignore
                workers: newData.workers.map((x) => {
                  return {
                    Id: x.id,
                    WorkerType: 'User',
                    TypeName: 'WorkerIdDto',
                  };
                }),
              });
              putWorkerPools({
                data,
                teamId,
              })
                .then(() => {
                  refetch();
                  dispatch(openSnackbar({ message: t('workerPoolEdit'), severity: 'success', display: true }));
                  resolve();
                })
                .catch((err) => {
                  console.debug('Failed while attempting to edit worker pool', err);
                  reject();
                });
            }
          }),
      }}
    />
  );
};

export default WorkerPoolEditor;
