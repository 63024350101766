import { blue, green, red } from '@constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressBook,
  faPhone,
  faAngleRight,
  faTimes,
  faSearch,
  faArrowDown,
  faPlus,
  faPlusCircle,
  faPencil,
  faTimesCircle,
  faSave,
  faColumns,
  faMinus,
  faCheck,
  faLock,
  faSyncAlt,
  faTrashAlt,
  faQuestionCircle,
  faArrowToBottom,
  faBars,
  faTrash,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronRight,
  faChevronLeft,
  faClone,
  faGearComplex,
} from '@fortawesome/pro-regular-svg-icons';

export const Icons = {
  AddressIcon: (): JSX.Element => <FontAwesomeIcon icon={faAddressBook} style={{ color: blue[500] }} />,
  PhoneNumberIcon: (): JSX.Element => <FontAwesomeIcon icon={faPhone} style={{ color: green[500] }} />,
  AddBox: (): JSX.Element => <FontAwesomeIcon icon={faPlusCircle} />,
  Cancel: (): JSX.Element => <FontAwesomeIcon icon={faTimesCircle} />,
  Save: (): JSX.Element => <FontAwesomeIcon icon={faSave} />,
  Cross: (): JSX.Element => <FontAwesomeIcon icon={faTimes} fixedWidth size="lg" style={{ color: red[500] }} />,
  Tick: (): JSX.Element => <FontAwesomeIcon icon={faCheck} fixedWidth size="lg" style={{ color: green[500] }} />,
  KeyboardArrowRight: (): JSX.Element => <FontAwesomeIcon icon={faAngleRight} />,
  LockIcon: (): JSX.Element => <FontAwesomeIcon icon={faLock} />,
  RefreshIcon: (): JSX.Element => <FontAwesomeIcon icon={faSyncAlt} style={{ color: blue[500] }} />,
  DeleteForeverIcon: (): JSX.Element => <FontAwesomeIcon icon={faTrashAlt} style={{ color: red[500] }} />,
  DeleteForeverDisabledIcon: (): JSX.Element => <FontAwesomeIcon icon={faTrashAlt} />,
  HelpIcon: (): JSX.Element => <FontAwesomeIcon icon={faQuestionCircle} style={{ color: blue[500] }} />,
  DownloadIcon: (): JSX.Element => <FontAwesomeIcon icon={faArrowToBottom} style={{ color: green[500] }} />,
  AddDisabled: (): JSX.Element => <FontAwesomeIcon icon={faPlus} />,
  Add: (): JSX.Element => <FontAwesomeIcon icon={faPlus} style={{ color: green[500] }} />,
  Check: (): JSX.Element => <FontAwesomeIcon icon={faCheck} />,
  Clear: (): JSX.Element => <FontAwesomeIcon icon={faTimes} />,
  Delete: (): JSX.Element => <FontAwesomeIcon icon={faTrash} />,
  DetailPanel: (): JSX.Element => <FontAwesomeIcon icon={faAngleRight} />,
  Edit: (): JSX.Element => <FontAwesomeIcon icon={faPencil} style={{ color: blue[500] }} />,
  SetDefault: (): JSX.Element => <FontAwesomeIcon icon={faGearComplex} />,
  Export: (): JSX.Element => <FontAwesomeIcon icon={faArrowToBottom} />,
  Filter: (): JSX.Element => <FontAwesomeIcon icon={faBars} />,
  FirstPage: (): JSX.Element => <FontAwesomeIcon icon={faChevronDoubleLeft} />,
  LastPage: (): JSX.Element => <FontAwesomeIcon icon={faChevronDoubleRight} />,
  NextPage: (): JSX.Element => <FontAwesomeIcon icon={faChevronRight} />,
  PreviousPage: (): JSX.Element => <FontAwesomeIcon icon={faChevronLeft} />,
  ResetSearch: (): JSX.Element => <FontAwesomeIcon icon={faTimes} fixedWidth />,
  Search: (): JSX.Element => <FontAwesomeIcon icon={faSearch} />,
  SortArrow: (): JSX.Element => <FontAwesomeIcon icon={faArrowDown} />,
  ThirdStateCheck: (): JSX.Element => <FontAwesomeIcon icon={faMinus} />,
  ViewColumn: (): JSX.Element => <FontAwesomeIcon icon={faColumns} />,
  Clone: (): JSX.Element => <FontAwesomeIcon icon={faClone} style={{ color: blue[500] }} />,
};
