import { configureStore, ThunkAction, Action, combineReducers, Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import {
  appReducer,
  appSlice,
  echoSlice,
  licenceSlice,
  mahonSlice,
  mfaSlice,
  openSnackbar,
  permissionSlice,
  roleSlice,
  serviceSlice,
  snackbarSlice,
  substitutionSlice,
  teamSlice,
  userReducer,
  userSlice,
} from '@slices';
import { echoApi, licenceApi, mahonApi, mfaApi, permissionApi, roleApi, serviceApi, substitutionApi, teamApi, userApi } from '@apis';

const reducers = {
  [appSlice.name]: appReducer,
  [userSlice.name]: userReducer,
  [userApi.reducerPath]: userApi.reducer,
  [snackbarSlice.name]: snackbarSlice.reducer,
  [teamSlice.name]: teamSlice.reducer,
  [teamApi.reducerPath]: teamApi.reducer,
  [roleSlice.name]: roleSlice.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [echoSlice.name]: echoSlice.reducer,
  [echoApi.reducerPath]: echoApi.reducer,
  [mfaSlice.name]: mfaSlice.reducer,
  [mfaApi.reducerPath]: mfaApi.reducer,
  [mahonSlice.name]: mahonSlice.reducer,
  [mahonApi.reducerPath]: mahonApi.reducer,
  [permissionSlice.name]: permissionSlice.reducer,
  [permissionApi.reducerPath]: permissionApi.reducer,
  [serviceSlice.name]: serviceSlice.reducer,
  [serviceApi.reducerPath]: serviceApi.reducer,
  [substitutionSlice.name]: substitutionSlice.reducer,
  [substitutionApi.reducerPath]: substitutionApi.reducer,
  [licenceSlice.name]: licenceSlice.reducer,
  [licenceApi.reducerPath]: licenceApi.reducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rtkQueryErrorLogger: Middleware = (_api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    let message = '';
    switch (action.payload.status) {
      case 500:
        console.debug('Handling 500 Internal Server Error');
        message = 'Please contact Lexacom Support with the following error code: \r' + action.payload.ExceptionId;
        break;
      case 400:
        console.debug('Handling 400 Bad Request', action.payload);
        message = action.payload.data.message ? action.payload.data.message : 'Unknown';
        break;
      case 401:
        console.debug('Handling 401 Unauthorised Request');
        message = action.payload.data.message ? action.payload.data.message : 'Unknown';
        break;
      case 403:
        console.debug('Handling 403 Unauthorised Request');
        message = action.payload.data.message ? action.payload.data.message : 'You do not have access to view this particular resource.';
        break;
      case 404:
        console.debug('Handling 404 Not Found');
        message = action.payload.data.message ? action.payload.data.message : 'Unknown';
        break;
      default:
        console.debug(action.payload);
        break;
    }
    next(openSnackbar({ message: message, severity: 'error', display: true }));
  }
  return next(action);
};

export const store = configureStore({
  reducer: combinedReducer,
  devTools: process.env.NODE_ENV === 'production' ? false : true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      userApi.middleware,
      teamApi.middleware,
      roleApi.middleware,
      echoApi.middleware,
      mfaApi.middleware,
      mahonApi.middleware,
      permissionApi.middleware,
      serviceApi.middleware,
      substitutionApi.middleware,
      licenceApi.middleware,
      rtkQueryErrorLogger,
    ]),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
