import { useDeactivateTeamMutation, useGetOwnerDataQuery, useGetTeamQuery, usePutMembershipInvitationRequiredMutation, usePutTeamMutation } from '@apis';
import { useAppDispatch, useAppSelector, useForm } from '@hooks';
import { TeamForm } from '@interfaces';
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Fade,
  Autocomplete,
  Tooltip,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Paper,
  Switch,
  CircularProgress,
  TextField,
  Dialog,
} from '@mui/material';
import { openSnackbar, setOwnersData } from '@slices';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReasonDialog } from '../ui';
import { useNavigate } from 'react-router';
import { RoutePath } from '@constants';
import { useAuth } from 'oidc-react';

const formInitialValues = {
  active: true,
  allowAutoUpdate: true,
  disableMembershipInvitations: true,
  mfaOnly: true,
  name: '',
  owner: '',
  parentCanAdministerChildren: true,
  postcode: null,
  sageCode: null,
  sageCostCentre: 0,
} as TeamForm;

type Props = {
  teamId: number;
};

const TeamDetailsEdit = ({ teamId }: Props): JSX.Element => {
  const { userRights } = useAppSelector((x) => x.app);
  const { userData } = useAuth();
  const { team, ownerData } = useAppSelector((x) => x.team);
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogOpenUpdateWarning, setDialogOpenUpdateWarning] = useState<boolean>(false);
  const [dialogOpenDeleteWarning, setDialogOpenDeleteWarning] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isMfaOptionDisabled, setIsMfaOptionDisabled] = useState<boolean>(true);

  const { isSuccess, isLoading, refetch } = useGetTeamQuery(teamId, {
    refetchOnMountOrArgChange: true,
  });
  useGetOwnerDataQuery(
    { teamId, searchTerm },
    {
      refetchOnMountOrArgChange: true,
      skip: searchTerm.length < 3,
    },
  );
  const [putTeam, { isLoading: isUpdatingTeam }] = usePutTeamMutation();
  const [postDeactivateTeam] = useDeactivateTeamMutation();
  const [putMembershitInvitation] = usePutMembershipInvitationRequiredMutation();
  const handleTeamSubmit = (): void => {
    putTeam({ newData: formData, teamId })
      .unwrap()
      .then(() => {
        refetch();
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to update a team', err);
        handleReset();
        return;
      });
  };

  const { handleSubmit, handleChange, formData, setFormData, errors, hasFormChanged, setHasFormChanged } = useForm<TeamForm>({
    validations: {
      name: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
      owner: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
    },
    onSubmit: handleTeamSubmit,
    initialValues: { ...formInitialValues },
  });

  useEffect(() => {
    if (userData?.profile.mfa_enabled === 'true' || userRights.isSysAdmin) {
      setIsMfaOptionDisabled(false);
    }
    dispatch(setOwnersData([]));
  }, []);

  const deactivateTeam = (reason: string): void => {
    postDeactivateTeam({ reason, teamId })
      .unwrap()
      .then(() => {
        refetch();
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to deactivate a team', err);
        return;
      });
  };

  const closeDialog = (): void => {
    setIsDialogOpen(false);
  };

  const activateTeam = () => {
    if (team) {
      putTeam({
        newData: {
          active: true,
          allowAutoUpdate: team.allowAutoUpdate,
          disableMembershipInvitations: team.disableMembershipInvitations,
          mfaOnly: team.mfaOnly,
          name: team.name,
          owner: team.owner,
          parentCanAdministerChildren: team.parentCanAdministerChildren,
          postcode: team.postcode,
          sageCode: team.sageCode,
          sageCostCentre: team.sageCostCentre,
        },
        isDeleted: false,
        teamId,
      })
        .unwrap()
        .then(() => {
          refetch();
          dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        })
        .catch((err) => {
          console.debug('Failed while attempting to update a team', err);
          return;
        });
    }
  };

  const deleteTeam = () => {
    if (team) {
      putTeam({
        newData: {
          active: team.active,
          allowAutoUpdate: team.allowAutoUpdate,
          disableMembershipInvitations: team.disableMembershipInvitations,
          mfaOnly: team.mfaOnly,
          name: team.name,
          owner: team.owner,
          parentCanAdministerChildren: team.parentCanAdministerChildren,
          postcode: team.postcode,
          sageCode: team.sageCode,
          sageCostCentre: team.sageCostCentre,
        },
        isDeleted: true,
        teamId,
      })
        .unwrap()
        .then(() => {
          dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
          setTimeout(() => {
            navigate(RoutePath.Home, { replace: true });
          }, 1500);
        })
        .catch((err) => {
          console.debug('Failed while attempting to deleate a team', err);
          return;
        });
      closeDeleteTeamWarning();
    }
  };

  const closeDeleteTeamWarning = (): void => {
    setDialogOpenDeleteWarning(false);
  };

  useEffect(() => {
    if (isSuccess && team) {
      setFormData({
        active: team.active,
        allowAutoUpdate: team.allowAutoUpdate,
        disableMembershipInvitations: team.disableMembershipInvitations,
        mfaOnly: team.mfaOnly,
        name: team.name,
        owner: team.owner,
        parentCanAdministerChildren: team.parentCanAdministerChildren,
        postcode: team.postcode,
        sageCode: team.sageCode,
        sageCostCentre: team.sageCostCentre,
      });
    }
  }, [isSuccess, team]);

  const handleReset = (): void => {
    setFormData(
      team
        ? {
            active: team.active,
            allowAutoUpdate: team.allowAutoUpdate,
            disableMembershipInvitations: team.disableMembershipInvitations,
            mfaOnly: team.mfaOnly,
            name: team.name,
            owner: team.owner,
            parentCanAdministerChildren: team.parentCanAdministerChildren,
            postcode: team.postcode,
            sageCode: team.sageCode,
            sageCostCentre: team.sageCostCentre,
          }
        : formInitialValues,
    );
    setHasFormChanged(false);
  };

  const handleMembershipInvitationChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setFormData({ ...formData, disableMembershipInvitations: checked });
    putMembershitInvitation({
      membershipInvitationRequired: checked,
      teamId,
    })
      .unwrap()
      .then(() => {
        refetch();
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to deleate a team', err);
        return;
      });
  };

  const handleAllowUpdateChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setFormData({ ...formData, allowAutoUpdate: checked });
    setHasFormChanged(true);
    if (!checked) setDialogOpenUpdateWarning(true);
  };

  return (
    <React.Fragment>
      <Paper className="w-full p-5" square>
        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Grid item xs={12} sm={8} md={9} lg={10}>
            <Typography variant="h6" gutterBottom>
              {t('teamEditPageTitle')}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              {t('teamEditPageSubtitle')}
            </Typography>
          </Grid>

          {userRights.isSysAdmin ? (
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      className="float-right"
                      checked={formData.disableMembershipInvitations}
                      onChange={handleMembershipInvitationChange}
                      color="primary"
                      name="disableMembershipInvitations"
                    />
                  }
                  label={t('teamEditPageMembershipInvitationsRequired')}
                />
              </FormGroup>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        {isLoading ? (
          <Fade in={isLoading} style={{ transitionDelay: isLoading ? '800ms' : '0ms' }} unmountOnExit>
            <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />
          </Fade>
        ) : (
          <Paper className="w-full p-6 mt-3">
            <form id="team-form" onSubmit={handleSubmit} className="w-full space-y-4">
              <Grid container item xs={12} sm={12} md={12} lg={12} spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} className="space-y-4">
                  <TextField
                    name="name"
                    label={t('teamName')}
                    value={formData.name}
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    {...(errors?.name && { error: true, helperText: errors.name })}
                  />

                  {userRights.isSysAdmin && (
                    <TextField
                      name="sageCode"
                      label={t('teamEditPageFormSageCode')}
                      value={formData.sageCode ?? ''}
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                      {...(errors?.sageCode && { error: true, helperText: errors.sageCode })}
                    />
                  )}
                  <TextField
                    name="postcode"
                    label={t('postcode')}
                    value={formData.postcode ?? ''}
                    inputProps={{ maxLength: 255 }}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                    {...(errors?.postcode && { error: true, helperText: errors.postcode })}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} className="space-y-4">
                  <Autocomplete
                    options={ownerData}
                    getOptionLabel={(option) => option}
                    autoComplete={false}
                    value={formData.owner}
                    onChange={(event, value) => handleChange(event, false, value as string, 'owner')}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        name="owner"
                        label={t('teamOwner')}
                        fullWidth
                        value={formData.owner}
                        onChange={(e) => setSearchTerm(e.currentTarget.value ?? '')}
                        {...(errors?.owner && { error: true, helperText: errors.owner })}
                      />
                    )}
                  />

                  {userRights.isSysAdmin && (
                    <TextField
                      name="sageCostCentre"
                      label={t('teamEditPageFormSageCostCentre')}
                      value={formData.sageCostCentre}
                      variant="outlined"
                      fullWidth
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                      {...(errors?.sageCostCentre && { error: true, helperText: errors.sageCostCentre })}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Switch checked={formData.allowAutoUpdate} onChange={handleAllowUpdateChange} color="primary" name="allowAutoUpdate" />}
                      label={t('teamEditPageFormAutoUpdate')}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  {team && team.type === 'Reseller' ? (
                    <FormGroup row>
                      <FormControlLabel
                        className="margin-top-left"
                        control={
                          <Switch checked={formData.parentCanAdministerChildren} onChange={handleChange} color="primary" name="parentCanAdministerChildren" />
                        }
                        label={t('teamEditPageFormParentCanAdminister')}
                      />
                    </FormGroup>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className="space-y-4">
                <FormGroup row>
                  <Tooltip title={isMfaOptionDisabled ? t('FormMfaOnlyTooltip') : ''}>
                    <FormControlLabel
                      className="margin-top-left"
                      control={<Switch checked={formData.mfaOnly} onChange={handleChange} disabled={isMfaOptionDisabled} color="primary" name="mfaOnly" />}
                      label={t('teamEditPageFormMfaOnly')}
                    />
                  </Tooltip>
                </FormGroup>
              </Grid>
              <Grid className="pt-2" container item xs={12} sm={12} md={12} lg={12}>
                {team?.active ? (
                  userRights.isSysAdmin ? (
                    <Grid item xs={12} sm={12} md={6} lg={6} className="justify-start pt-5">
                      <Button variant="contained" color="secondary" disabled={isUpdatingTeam || team?.isSysAdmin} onClick={() => setIsDialogOpen(true)}>
                        {t('deactivateButtonText')}
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
                  )
                ) : (
                  <Grid item xs={12} sm={12} md={6} lg={6} className="justify-start pt-5">
                    <Button variant="contained" className="!mr-5" color="primary" disabled={isUpdatingTeam || !userRights.isSysAdmin} onClick={activateTeam}>
                      {t('activateButtonText')}
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={isUpdatingTeam || !userRights.isSysAdmin || team?.isSysAdmin}
                      onClick={() => setDialogOpenDeleteWarning(true)}>
                      {t('deleteText')}
                    </Button>
                  </Grid>
                )}

                <Grid container item xs={12} sm={12} md={6} lg={6} className="justify-end pt-5">
                  <Button className="!mr-5" variant="contained" color="secondary" onClick={handleReset} disabled={!hasFormChanged || isUpdatingTeam}>
                    {t('clearButtonText')}
                  </Button>
                  <Button variant="contained" color="primary" type="submit" disabled={!hasFormChanged || isUpdatingTeam}>
                    {isUpdatingTeam && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                    {t('submitButtonText')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        )}

        <Dialog open={dialogOpenUpdateWarning}>
          <DialogTitle>{t('automaticUpdateWarningTitle')}</DialogTitle>
          <DialogContent>
            <Typography gutterBottom variant="body1">
              {t('disabledApplicationAutoUpdate')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => setDialogOpenUpdateWarning(false)}>
              {t('dismissButtonText')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={dialogOpenDeleteWarning} onClose={closeDeleteTeamWarning}>
          <DialogTitle>{t('deleteTeamWarningTitle')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('deleteTeamWarningText1')}
              <br></br>
              <br></br>
              <strong>{t('deleteTeamWarningText2')}</strong>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteTeam} color="secondary">
              {t('confirm')}
            </Button>
            <Button onClick={closeDeleteTeamWarning} autoFocus>
              {t('cancelButtonText')}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
      <ReasonDialog
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
        action={deactivateTeam}
        title={t('deactivateTeamReasonDialogTitle')}
        text={t('deactivateTeamReasonDialogText')}
      />
    </React.Fragment>
  );
};
export default TeamDetailsEdit;
