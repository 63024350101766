import { usePutLicenceMutation } from '@apis';
import { useAppDispatch, useForm } from '@hooks';
import { LicenceFormItem, LicenceItem } from '@interfaces';
import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Paper, TextField } from '@mui/material';
import { openSnackbar } from '@slices';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useState } from 'react';
import { ConfirmationDialog } from '../ui';

type Props = {
  onClose: () => void;
  refetch?: () => void;
  licence: LicenceItem;
};

const EditLicenceForm = ({ refetch, onClose, licence }: Props): JSX.Element => {
  const { t } = useTranslation('pano');

  const formInitialValues = {
    count: licence.count,
    trial: licence.trial,
    unlimited: licence.unlimited,
    startDate: new Date(new Date().setHours(0, 0, 0, 0)),
    expirationDate: null,
  } as LicenceFormItem;

  const dispatch = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [editLicence, { isLoading }] = usePutLicenceMutation();

  const handleLicenceSubmit = (): void => {
    if (formData.expirationDate) setDialogOpen(true);
    else handleUpdateLicence();
  };

  const handleUpdateLicence = (): void => {
    editLicence({
      ...licence,
      trial: formData.trial,
      unlimited: formData.unlimited,
      startDate: formData.startDate.toISOString(),
      count: formData.count,
      endDate: formData.trial ? null : formData.expirationDate ? formData.expirationDate.toISOString() : null,
      trialEnd: !formData.trial ? null : formData.expirationDate ? formData.expirationDate.toISOString() : null,
    })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        if (refetch) refetch();
        handleClearAndClose();
      })
      .catch((err) => {
        console.debug('Failed while attempting to edit licence', err);
        return;
      });
  };

  const { handleSubmit, handleChange, formData, setFormData, errors } = useForm<LicenceFormItem>({
    validations: {},
    onSubmit: handleLicenceSubmit,
    initialValues: { ...formInitialValues },
  });

  const handleStartDateChange = (date: Date | null): void => {
    if (date) setFormData({ ...formData, startDate: date });
  };

  const handleExpirationDateChange = (date: Date | null): void => {
    setFormData({ ...formData, expirationDate: date });
  };

  const handleClearAndClose = (): void => {
    setFormData({ ...formInitialValues });
    onClose();
  };

  const handleCloseDialog = (): void => {
    setDialogOpen(false);
  };

  const calculateDaysDifference = (startDate: Date, expirationDate: Date): number => {
    const oneDay = 1000 * 60 * 60 * 24;
    const differenceInTime = expirationDate.getTime() - startDate.getTime();
    const differenceInDays = Math.round(differenceInTime / oneDay);
    return differenceInDays;
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Paper className="w-full p-5 space-y-4 mb-4 mt-4">
          <Grid container item xs={12} sm={12} md={12} lg={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                name="count"
                autoComplete="nope"
                label={t('count')}
                placeholder={t('name') as string}
                fullWidth
                type="number"
                inputProps={{ min: licence.userLicences.length }}
                value={formData.count}
                onChange={handleChange}
                variant="outlined"
                {...(errors?.count && { error: true, helperText: errors.count })}
              />
            </Grid>
            <Grid container item xs={12} sm={12} md={6} lg={6} spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormGroup row>
                  <FormControlLabel control={<Checkbox checked={formData.trial} onChange={handleChange} color="primary" name="trial" />} label={t('trial')} />
                </FormGroup>
              </Grid>
              {!formData.trial && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={formData.unlimited} onChange={handleChange} color="primary" name="unlimited" />}
                      label={t('unlimited')}
                    />
                  </FormGroup>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={12} md={12} lg={12} spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={t('startDate')}
                  format="dd/MM/yyyy"
                  disablePast
                  value={formData.startDate}
                  maxDate={formData.expirationDate ?? undefined}
                  onChange={handleStartDateChange}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={t('expirationDate')}
                  format="dd/MM/yyyy"
                  disablePast
                  value={formData.expirationDate}
                  minDate={formData.startDate}
                  onChange={handleExpirationDateChange}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Paper>
        <Grid container item xs={12} sm={12} md={12} lg={12} className="justify-end pt-3">
          <Button variant="contained" className="!mr-2" color="secondary" onClick={handleClearAndClose} disabled={isLoading}>
            {t('cancelButtonText')}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
            {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('submitButtonText')}
          </Button>
        </Grid>
      </form>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={t('pleaseConfirm')}
        message={
          calculateDaysDifference(formData.startDate, formData.expirationDate ?? new Date()) === 0
            ? `${t('licenceEndTimeSameAsStartTime')}`
            : `${t('theLicenceWillExpireIn')} ${calculateDaysDifference(formData.startDate, formData.expirationDate ?? new Date())} ${t('days')}. ${t(
                'doYouWishToContinue',
              )}`
        }
        onConfirm={handleUpdateLicence}
        cancelButtonText={t('no') as string}
        confirmButtonText={t('yes') as string}
      />
    </>
  );
};

export default EditLicenceForm;
